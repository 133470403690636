<template>
  <div>
    <vs-input
      v-validate="'required|min:8'"
      data-vv-validate-on="blur"
      data-vv-as="رقم الهاتف"
      name="phone"
      icon-no-border
      icon="icon icon-user"
      icon-pack="feather"
      label-placeholder="رقم الهاتف"
      v-model="phone"
      class="w-full"
    />
    <span class="text-danger text-sm">{{ errors.first("phone") }}</span>

    <vs-input
      data-vv-validate-on="blur"
      v-validate="'required|min:8|max:50'"
      data-vv-as="كلمة السر"
      type="password"
      name="password"
      icon-no-border
      icon="icon icon-lock"
      icon-pack="feather"
      label-placeholder="كلمة المرور"
      v-model="password"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("password") }}</span>

    <div class="flex flex-wrap justify-between my-5">
      <vs-checkbox v-model="checkbox_remember_me" class="mb-3"
        >تذكرني</vs-checkbox
      >
      <router-link to="/pages/forgot-password"
        >هل نسيت كلمة المرور؟</router-link
      >
    </div>
    <div class="flex flex-wrap justify-between mb-3">
      <vs-button :disabled="!validateForm" @click="login">تسجيل دخول</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phone: "",
      password: "",
      checkbox_remember_me: false,
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "" && this.password != "";
    },
  },
  created(){
      if (!this.checkLogin()){
        this.$router.push('/home')
      }
    },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: "محاولة تسجيل دخول",
          text: "تم تسجيل الدخول من قبل بالفعل",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        })

        return false;
      }
      return true;
    },
    
    login() {
      
      this.$vs.loading();

      const payload = {
        //checkbox_remember_me: this.checkbox_remember_me,
        phone: this.phone,
        password: this.password,
      };

      this.$store
        .dispatch("auth/login", payload)
        .then(() => {
          this.$vs.loading.close();
          this.$router.push('/home')
        })
        .catch(() => {
          this.$vs.loading.close();

          this.$vs.notify({
            time: 5000,
            title: "خطأ",
            text: "كلمة المرور او رقم الهاتف خطأ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
  },
};
</script>

<style>
</style>
