<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/logo/almithalylogo.svg" height="150" alt="login" class="mx-auto">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="px-8 pt-8 login-tabs-container">

                <div class="vx-card__title mb-10">
                  <h4 class="mb-4">تسجيل الدخول</h4>
                  <p>رجاءاً تسجيل الدخول الى حسابك</p>
                </div>

               <LoginForm></LoginForm>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>


<script>
import LoginForm from "./LoginForm.vue"


export default {
  components: {
    LoginForm,
  }
}
</script>

<style lang="scss">
.login-tabs-container {
  min-height: 505px;
}
</style>
